<template>
  <router-view></router-view>
</template>


<script>
export default {
  name: "MainComponentRouterView",
  props: {},
  components: {},

  data() {
    return {};
  },

  mounted() {
    this.getAndTransferToken();
    this.$store.dispatch("getUserInfo");
  },

  methods: {
    getAndTransferToken() {
      const token = this.$route.query.access_token;
      this.$store.commit("updateToken", { access_token: token });
    },
  },

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
</style>